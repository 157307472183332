export const dictionary = {
    az: {
        about: "Haqqında",
        aboutUs:"Bizim haqqımızda",
        aboutUsDesc:"Biz qərəzsiz və müstəqilik və hər gün dünyada milyonlarla insanı məlumatlandıran, öyrədən və əyləndirən fərqli, dünya səviyyəli proqramlar və məzmunlar yaradırıq.",
        fullName: "Ad və soy ",
        fullNamePlaceholder:"Ad və soyadınızı daxil edin...",
        emailAddress: "E-mail ünvanı",
        emailAddressPlaceholder:"E-mail ünvanınızı daxil edin...",
        message: "Mesaj",
        contact: "Əlaqə",
        contactUs: "Bizimlə əlaqə",
        address: "Ünvan",
        addressDesc: "Foto kabinəsi döymə prizma, portland taiyaki kapüşonlu neutra yazı maşını",
        email: "E-MAIL",
        emailDesc: "nc.example@example.com",
        phone: "Telefon",
        phoneDesc: "000-123-456-7890",
        socials: "SOSİAL",
        submitBtn: "Göndər",
        newsletterHeading: "Xəbər bülletenimizə qoşulun",
        newsletterDesc:"İstənilən mövzuda yeni perspektivləri oxuyun və paylaşın. Hər biriniz xoş gəlmisiniz.",
        dropUsMessage: "Bizə mesaj yaz və biz sizə geri dönüş edəcəyik.", 
        booksSectionHeader: "Kitablarım",
        booksSectionDesc:"Gələcək yaradıcı potensiallara salam deyin",
        founderSectionHeader: "Yaradıcı",
        founderSectionDesc:"Biz qərəzsiz və müstəqilik və hər gün fərqli, dünya səviyyəli proqramlar və məzmunlar yaradırıq",
        successTxt: 'Əməliyyat uğurla icra olundu',
        errorOccurred: 'Xəta baş verdi',
        viewAll: 'Hamısına bax',
        articles: 'Məqalələr',
        newsletter: "Xəbərlər",
        gallery: 'Qalereya',
        author: 'Müəllif',
        books: 'Kitablar',


    },
    en: {
        about: "About",
        aboutUs: "About us",
        aboutUsDesc:"We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world.",
        fullName: "Full Name",
        emailAddress: "E-mail Address",
        emailAddressPlaceholder: "Enter your E-mail address...",
        message: "Message",
        contact: "Contact",
        contactUs: "Contact us",
        dropUsMessage: "Drop us message and we will get back for you.",
        address: "Address",
        addressDesc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
        email: "EMAIL",
        emailDesc: "nc.example@example.com",
        phone: "PHONE",
        phoneDesc:"000-123-456-7890",
        socials:"SOCIALS",
        submitBtn: "Submit",
        newsletterHeading: "Join our newsletter",
        newsletterDesc:"Read and share new perspectives on just about any topic. Everyone’s welcome.",
        booksSectionHeader: "My Books",
        booksSectionDesc: "Say hello to future creator potentials",
        founderSectionHeader: "Founder",
        founderSectionDesc:"We’re impartial and independent, and every day we create distinctive, world-class programmes and content",
    }
  };
  

export const formErrorMessages = {
    az: {
        fullName: "Tam ad tələb olunur!" ,
        email: "Email tələb olunur!",
        invalidEmailError:"Zəhmət olmasa düzgün email daxil edin!",
        message: "Mesaj tələb olunur!",
        invalidMessageError:"Maksimum 300 simvol olar",
      }
  }